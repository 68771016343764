import { TemplateRead, TemplatesApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import React, { useCallback } from "react";
import {
  Box,
  Button,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  HStack,
  useDisclosure,
  VStack,
  Card,
  CardFooter,
  CardBody,
  Flex,
  Tag,
  LinkBox,
  LinkOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { MdInfo } from "react-icons/md";
import Modal from "src/components/Modal";
import CommonCard from "src/CommonCard";
import { createCustomNodes, getLayoutedElements } from "src/appUtils";
import Markdown from "./markdown";
import { useData } from "vike-react/useData";

interface TemplateAndFlowData {
  template: TemplateRead;
  edges: any[];
  nodes: any[];
}

const AutoLayoutFlow = ({ edges: initialEdges, nodes: initialNodes, customNodes }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const reactFlow = useReactFlow();

  const isInitializedRef = React.useRef(false);

  const onLayout = useCallback(() => {
    const layouted = getLayoutedElements(nodes, edges);
    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);
  }, [nodes, edges]);

  React.useEffect(() => {
    if (!reactFlow) {
      return;
    }
    if (
      isInitializedRef.current === false &&
      nodes.length > 0 &&
      (nodes?.[0]?.measured?.width || 0) > 0
    ) {
      setTimeout(() => {
        // adding a delay to **increase the probs** the nodes are rendered before fitting the view
        onLayout();
      }, 100);
      isInitializedRef.current = true;
    } else if (isInitializedRef.current === true) {
      reactFlow.fitView();
      isInitializedRef.current = false;
    }
  }, [nodes, edges, reactFlow]);

  // React.useEffect(() => {
  //   if (typeof window === 'undefined') return;
  //   onLayout();
  //   console.debug('first');
  //   window.requestAnimationFrame(() => {
  //     fitView();
  //   });
  // }, [])

  return (<Box
    h="200px"
    p='1'
    bg='white'
    rounded="10"
    shadow='2xl'>
    <ReactFlow
      fitView
      edges={edges}
      nodes={nodes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={() => null}
      edgesFocusable={false}
      nodesDraggable={false}
      nodesConnectable={false}
      nodesFocusable={true}
      elementsSelectable={true}
      zoomOnScroll={false}
      panOnScroll={false}
      preventScrolling={false}
      nodeTypes={customNodes || {}}
    >
    </ReactFlow>
  </Box>)
}

export const TEMPLATES_BGS = ['brandTernary.100', 'brandSecondary.100', 'brandQuaternary.100'];

export const ExploreCard = ({ root, template, edges, nodes, customNodes, bg }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });
  const borderColor = useColorModeValue("gray.300", "gray.500")
  return <>
    <Modal header={template.name} isOpen={isOpen} onClose={onToggle} size='6xl'>
      <Markdown fontSize="lg">{template.desc}</Markdown>
    </Modal>
    <GridItem
      key={template.id}
      as={LinkBox}
      boxShadow='lg'
    >
      <Card rounded="10" bg={bg} h='full' as={Flex} borderWidth='1px' borderColor={borderColor}>
        <CardBody p='4'>
          <ReactFlowProvider initialHeight={200} initialWidth={330} fitView>
            <AutoLayoutFlow edges={edges} nodes={nodes} customNodes={customNodes} />
          </ReactFlowProvider>
        </CardBody>
        <CardFooter bg='white' p={2} flex='1' roundedBottom="10">
          <VStack align='stretch' w='full' justify='space-between' h='full'>
            <LinkOverlay href={root + `/${template.id}`}>
              <Heading size="sm" sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
              }}
              >{template.name}</Heading>
            </LinkOverlay>
            <Flex w='full' justify='space-between'>
              {template.is_featured ? (
                <Tag size='sm' >
                  <HStack spacing={1}>
                    <Text>
                      Featured 🚀
                    </Text>
                  </HStack>
                </Tag>) : <Box />
              }
              <Button
                size='xs'
                aria-label="info"
                bg='white'
                color='gray.600'
                leftIcon={<MdInfo />}
                // colorScheme="brand"
                onClick={onToggle}>
                About this template
              </Button>
            </Flex>
          </VStack>
        </CardFooter>
      </Card>
    </GridItem >
  </>
}

const Explore = ({ root }) => {
  const { templates, nodeTypes } = useData();
  const customNodes = React.useMemo(() => createCustomNodes({ nodeTypes, isDemo: true, defaultIsExpanded: false }), [nodeTypes])

  return (
    <Box p={4}>
      <CommonCard
        heading="Pre-built templates"
        subtitle="Jump start your AI flow with these templates. Click on a template to get started in minutes!"
      >
        {templates === null ? (
          <Spinner />
        ) : (
          <SimpleGrid
            columns={{ base: 1, md: 3, lg: 4 }}
            spacing={{ base: 8 }}
          >
            {
              templates.map(({ template, edges, nodes }, ix) => <ExploreCard bg={TEMPLATES_BGS[ix % TEMPLATES_BGS.length]}
                root={root} key={template.id} template={template} edges={edges} nodes={nodes} customNodes={customNodes} />)
            }
          </SimpleGrid>
        )}
      </CommonCard>
    </Box>
  );
};

export default Explore;
